import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Book from './pages/Book';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import AuthenticatedHome from './pages/AuthenticatedHome';
import Loading from '../src/partials/Loading';

function App() {

  const location = useLocation();
  const { user, isAuthenticated, isLoading } = useAuth0();
  console.log(isAuthenticated + " auth");

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <AuthenticatedHome />
        </Route> 
        <Route path="/beta">
          <Book />
        </Route> 
        <Route path="/signin">
          <SignIn />
        </Route> 
        <Route path="/signup">
          <SignUp />
        </Route> 
      </Switch>
    </>
  );
}

export default App;
