import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../navbar/Logo';
import MobileNavbar from './MobileNavbar';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../../responsive";
import Navbar from './Navbar';

function Header() {

  const [top, setTop] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header>
      {/* <header className={`fixed w-full z-30  duration-300 ease-in-out bg-white ${(isMobile && !top) ? 'shadow-lg' : !isMobile && !top && 'blur shadow-lg bg-opacity-90 transition '}`}> */}
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="TukTuk">
              <Logo></Logo>
            </Link>
          </div>

          {/* Site navigation */}
          {/* {isMobile && <MobileNavbar />} */}
          {/* {!isMobile && <Navbar/>} */}
          <Navbar></Navbar>
        </div>
      </div>
    </header>
  );
}

export default Header;
