import React from "react";
import TukTukImage from "../../images/logo.png";

function Logo() {
  return (
    <div className="flex items-center">
      <div className="">
        <img className="w-auto h-8 sm:h-10" src={TukTukImage} alt="TukTuk logo" />
      </div>
      <div className="sm:text-2xl m-0 ml-2 font-medium">
        Tuktuk
      </div>
    </div>
  );
}

export default Logo;