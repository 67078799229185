import React, { useState } from "react";
import MenuToggle from "../MenuToggle";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

function MobileNavbar() {
  const [isOpen, setOpen] = useState(false);

  const variants = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    hidden: {
      clipPath: "circle(30px at 40px 40px)",
      transition: {
        delay: 0.1,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  }

  return (
    <div className="h-full flex bg-white z-50 items-center">
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      <motion.nav initial={false}
        animate={isOpen ? "open" : "hidden"}
        variants={variants}
      >

        <ul className={`pt-40 m-0 p-0 ${!isOpen ? 'hidden' : 'flex'} h-full list-none bg-white w-full flex-col fixed overflow-hidden left-0 ease-in-out duration-200`} style={{ top: 64 }}>
          {/* <li className="p-0 pr-4 font-medium text-base flex mb-3 justify-center h-12">
            <Link to="/book" className="w-3/4 text-center text-2xl font-medium text-gray-600 hover:text-gray-900">Book</Link>
          </li>
          <li className="p-0 pr-4 font-medium text-base flex mb-3 justify-center h-12">
            <Link to="/signin" className="w-3/4 text-center text-2xl font-medium text-gray-600 hover:text-gray-900">Sign in</Link>
          </li> */}
          <li className="w-full p-0 pr-4 font-medium text-base flex mb-3 justify-center h-12">
            {/* <Link to="/beta" className="text-2xl btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
              <span>waitlist</span>
              <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
              </svg>
            </Link> */}
          </li>
          <div className="h-8"> </div>
        </ul>
      </motion.nav>
    </div>
  );
}

export default MobileNavbar;
