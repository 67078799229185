import React from 'react';
import map from '../images/map.png'

function Routes() {
  return (
    <section className="relative bg-gray-100">

      {/* Illustration behind content */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4">Routes</h2>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out">Tuktuk covers travel between UT Austin, West Campus, Hyde Park, HEB  Downtown, Zilker, and events such as ACL and UT football games.</p>
          </div>
          <div className="flex justify-center items-center rounded-lg w-3/4 ml-auto mr-auto sm:w-1/2">
            <img src={map} className="rounded-3xl border border-black-200 shadow-xl" alt="map displaying all routes"></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Routes;