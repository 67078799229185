import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/navbar/Header';
import Footer from '../partials/Footer';

function Book() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <div className="flex-grow flex justify-content-center align-middle justify-center w-full h-full">
        {/* <iframe className="relative h-screen mt-12" data-aos="zoom-y-out" src="https://airtable.com/embed/shrGE0OG6Ld8bXtF4?backgroundColor=teal" width="90%" height="100%"></iframe> */}
        <iframe class="airtable-embed airtable-dynamic-height pt-20" data-aos="zoom-y-out" src="https://airtable.com/embed/shrdUbyN8Bl7t3T43?backgroundColor=teal" frameborder="0" onmousewheel="" width="100%"></iframe>
      </div>

      <Footer />

    </div>
  );
}

export default Book;