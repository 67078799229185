import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = "dev-exsxbprl.us.auth0.com";
  const clientId = "whPNYONPPg4TEvvlVR0IVAw0QC4I4Czx"

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri="https://arks007.github.io/landingpage/home" 
      >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;