import React from 'react';
import money from '../images/money.svg'
import car from '../images/car.svg'
import lock from '../images/lock.svg'

function FeaturesBlocks() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 md:mt-24 lg:mt-0 pointer-events-none gray-background" aria-hidden="true"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-10 md:pb-8">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="h2">Why Tuktuk ?</h2>
            {/* <p className="text-xl text-gray-600">TukTuk provides a alternative form of transportation that is less expensive than ride sharing apps and more convenient than public transport.</p> */}
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none" style={{ marginTop: "40px" }}>

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl" style={{ height: "195px" }}>
              <div className="flex items-center justify-center w-16 h-16 p-1 -mt-1 mb-2 rounded-full" style={{ width: "56px", height: "56px", background: "linear-gradient(45deg, #549cd9, #69c5bf)" }}>
                <img src={money} style={{ height: "28px" }}></img>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Super Affordable</h4>
              <p className="text-gray-600 text-center">Tuktuk rides cost 60%-70% less than Uber and Lyft  </p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl" style={{ height: "195px" }}>
              <div className="flex items-center justify-center w-16 h-16 p-1 -mt-1 mb-2 rounded-full bg-blue-600" style={{ width: "56px", height: "56px", background: "linear-gradient(45deg, #549cd9, #69c5bf)" }}>
                <img src={car} style={{ height: "28px" }}></img>
              </div>
              {/* <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Quick Rides</h4>
              <p className="text-gray-600 text-center">Only four minutes longer than other ride sharing apps on average </p> */}
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Skip The Bus</h4>
              <p className="text-gray-600 text-center">Travel comfortably and skip the stuffy, drawn out bus rides </p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center w-16 h-16 p-1 -mt-1 mb-2 rounded-full bg-blue-600" style={{ width: "56px", height: "56px", background: "linear-gradient(45deg, #549cd9, #69c5bf)" }}>
                <img src={lock} style={{ height: "28px" }}></img>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Safety First</h4>
              <p className="text-gray-600 text-center">Ride with drivers with industry standard background checks</p>
            </div>

            <div className="none sm:block"></div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
