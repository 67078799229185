import React from 'react';

import Header from '../partials/navbar/Header';
import HeroHome from '../partials/HeroHome';
import Footer from '../partials/Footer';

import { useAuth0 } from "@auth0/auth0-react";
import Features from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlock';
import Routes from '../partials/Routes';

function Home() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <Header />
      <main className="flex-grow">
        <HeroHome />
      </main>
      <Features />
      <FeaturesBlocks />
      <Routes />

      <Footer />

    </div>
  );
}

export default Home;