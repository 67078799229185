import React, { useState } from 'react';
import '../css/additional-styles/other-styles.css'
import { Link } from 'react-router-dom';

function HeroHome() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative main-tuktuk-section">

      {/* Illustration behind hero content */}
      <img className="A hidden md:block shadow-2xl absolute inset-x-1.5 bottom-0 rounded-full h-48 w-48 transition duration-300 transform sm:hover:-translate-y-1 sm:hover:scale-100 object-cover" src={require('../images/concert.jpeg').default} style={{ top: "55rem", left: '8rem' }} />
      <img className="B hidden md:block shadow-2xl absolute inset-x-1.5 bottom-0 rounded-full h-56 w-56 transition duration-300 transform sm:hover:-translate-y-1 sm:hover:scale-100 object-cover" src={require('../images/capitol.jpeg').default} style={{ top: "35rem", right: '4.5rem' }} />
      <img className="B hidden md:block shadow-2xl absolute inset-x-1.5 bottom-0 rounded-full h-56 w-56 transition duration-300 transform sm:hover:-translate-y-1 sm:hover:scale-100 object-cover" src={require('../images/austin.jpeg').default} style={{ top: "9rem", left: '1rem' }} />

      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-16 md:pt-16 md:pb-12">

          {/* Section header */}
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Carpooling<br /> <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Reimagined</span></h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">Tuktuk provides convenient and scheduled carpooling in Austin </p>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center iphone-main" data-aos="zoom-y-out" data-aos-delay="300">
                <img id="hero" className="z-20 h-35 mx-auto mb-8 transition duration-300 transform sm:hover:-translate-y-1 sm:hover:scale-105 mt-10" src={require('../images/explore-phone.png').default} alt="Hero" />
              </div>
              <p className="text-xl text-gray-600 mb-0 text-center" data-aos="zoom-y-out" data-aos-delay="200">Sign up for the beta below</p>
              <div className="flex flex-wrap justify-center">
                <div className="w-full px-3 mb-4">
                  {/* appstore link, to add a link to download the app */}
                  {/* <a href="https://apps.apple.com/us/app/browser-and-documents-manager/id979401801?itsct=apps_box_badge&amp;itscg=30200"
                    className="inline-block overflow-hidden rounded-sm w-60 h-20" ><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1455926400&h=8fb593090a792b947d2992c28f7610df" alt="Download on the App Store" className="rounded-xl w-48 h-16 m-auto" /></a> */}
                </div>
                {/* replace when the waitlist is over */}
                {/* <p className="text-xl text-gray-600 mb-8 text-center" data-aos="zoom-y-out" data-aos-delay="100">Rides to downtown starting at $5</p> */}
                <a href="https://utexas.qualtrics.com/jfe/form/SV_1zzWk97R77U7Gwm" target="_blank" className="text-2xl btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3 mt-6 mb-10 sm:mb-0">
                  <span> beta waitlist </span>
                </a>
                <a href="https://www.youtube.com/watch?v=E2HQiC-9VkI" target="_blank" className="text-2xl btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3 mt-6 mb-10 sm:mb-0">
                  <span> app demo </span>
                </a>
                {/* <a href="https://utexas.qualtrics.com/jfe/form/SV_8HUnZ2Ma8ORQcXs" target="_blank" className="text-2xl btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3 mt-6 mb-10 sm:mb-0">
                  <span>driver waitlist</span>
                </a> */}
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default HeroHome;