import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/navbar/Header';
import Footer from '../partials/Footer';
import Logo from '../partials/navbar/Logo'
import Loading from '../partials/Loading';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Home from './Home';

const AuthenticatedHome = () => {
  const { logout, user, isLoading, isAuthenticated } = useAuth0();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <header className={`fixed w-full z-30  duration-300 ease-in-out bg-white `}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="TukTuk">
              <Logo></Logo>
            </Link>
          </div>
          {/* Site navigation */}
          <nav className="flex flex-grow">
			<ul className="flex flex-grow justify-end flex-wrap items-center">
				<li>
					<Link onClick={() => logout({returnTo: window.location.origin})} className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
						<span>Log Out</span>
					</Link>
				</li>
			</ul>
		</nav>
        </div>
      </div>
    </header>

      {/*  Page content */}
      <div className="flex-grow flex justify-content-center align-middle justify-center w-full h-full">
        <iframe class="airtable-embed airtable-dynamic-height pt-20" data-aos="zoom-y-out" src="https://airtable.com/embed/shrGE0OG6Ld8bXtF4?backgroundColor=teal" frameborder="0" onmousewheel="" width="100%" height="1625"></iframe>
      </div>

      <Footer />

    </div>
  );
}

export default withAuthenticationRequired(AuthenticatedHome, {
  onRedirecting: () => <Loading />,
});
